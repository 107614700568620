export   const jp = {
    login: {
        "App Store 下载":"App Store",
		"Google Play 下载":"Google Play",
        "注册":"今すぐ口座開設",
        "title": "クレジットカードで<br/>仮想通貨を<br/>購入しましょう！",
        title2: "14000USDT",
        title3: "新規ユーザー限定",
        "li1": "2011年に設立されたBTCCは、世界運営時間No.1の取引所です",
        "li2": "米国MSB、カナダMSB取得",
        "li3": "18個以上の仮想通貨取引に対応",
        "li4": "9つの法定通貨での入金が可能",
        "li5": "1～150のレバレッジを提供",
        "li6": "口座開設後7日以内に入金条件を達成すると、ボーナスを獲得できます。",
        "tab1":"メールアドレスで登録",
        "email": "メールアドレスを入力してください",
        "code": "認証コードを入力してください",
        "Send": " 認証コードを送信",
        "pwd": "パスワードを設定してください",
        "tab2": "電話で登録",
        "mobile": "電話番号を入力してください",
        "Register": "今すぐ口座開設",
        "服务条款":"https://www.btcc.com/ja-JP/detail/148559.html",
        "隐私声明":"https://www.btcc.com/ja-JP/detail/148560.html",
        "风险提示":"https://www.btcc.com/ja-JP/detail/149038.html",
        "首页url":"https://lp.btcc.academy/ja-JP",
        "首页":"ホーム",
        "关于我们":"BTCCについて",
        "about":"ja-JP/about-jp.html",
        "about_in":"about_in-jp.html",
        "about_dm":"about_dm-jp.html",
        "about_coupon":"about_coupon-jp.html",
        "about_rebate":"about_rebate-jp.html",
        "about_lottery":"about_lottery-jp.html",

    mobileTip: "正しい電話番号を入力してください",
    emailTip: "メールアドレスを正確に入力してください",
    codeTip: "正しい認証コードを入力してください",
    pwdTip: "8文字以上で、大文字、小文字、数字・記号の組み合わせが必要です。",
    tips: "をよく読み、了承します",
    tips1: "「サービス利用規約」",
    tips2: "「プライバシーポリシー」",
    tips3: "「リスク開示」",
    tipslang: "https://www.btcc.com/detail/142641.html",

    registeSuccessInfo: "登録完了！仮想通貨の旅を始めましょう!",
    startTrading: "今すぐBTCCをダウンロードし、新規ユーザーボーナスを手に入れましょう!",
    registeSuccessInfoPhone: "登録完了！仮想通貨の旅を始めましょう",
    startTradingPhone: "今すぐBTCCをダウンロードし、新規ユーザーボーナスを手に入れましょう!",
  },
  header:{
    充值:"仮想通貨預入",
    交易:"取引",
    学习:"アカデミー",
    最新活动:"最新の活動",
    更多:"関連情報",
    充幣:"コイン預入",
    買幣:"仮想通貨購入",
    合約:"先物",
    閃兌:"コイン交換",
    學院:"BTCCアカデミー",
    資訊:"ニュース",
    幫助中心:"ヘルプセンター",
    官方部落格:"公式ブログ",
    新用戶專享:"新規ユーザ限定",
    交易回饋:"取引でキャッシュバック",
    邀请好友:"友だちを招待する",
    公告中心:"お知らせ",
    下載中心:"ダウンロード",
    推薦計劃:"アフィリエイト",
    VIP升級:"VIPプログラム",
    註冊:"会員登録",
  
  },
  SupportedCurrencies: {
    title: "対応通貨",
    title2:"BTCCが選ばれる理由",
    li1_1: "BTCCは2011年に設立され、世界で運営実績No.1。11年間の堅実運営でハッキングされたことがない",
    li2: "米国、カナダのMSBライセンスを保有",
    li3: "18種類以上のメジャーコインを取扱う",
    li4: "10倍-150倍のレバレッジで取引可能",
    li5: "14,000USDTの新規ユーザー限定ボーナス",
    title2:"BTCCが選ばれる理由",
    p: "お使いになる通貨を選び、入金しましょう。",
  },
  LeverageUp: {
    title: "18個のメジャーコイン・通貨を<br/>最大150倍のレバレッジで",
    head1: "レバレッジ",
    head2: "仮想通貨",
    p: "これからもコインを追加する予定です。",
  },
  cost: {
    title: "世界基準の低い手数料",
    p: "ほかの仮想通貨取引所と比べ、BTCCは取引コストを極限までに抑え、<br/>世界基準から見てもかなり低い手数料を提供しております。",
    h3: "取引手数料",
    p2: "注文をするごとにわずか0.03％の取引手数料。取引を行う際は手数料を気にしなくても大丈夫！",
    h4: "スプレッド",
    p3: "注文する時に躊躇しますか？BTCCは最も正確な価格を提供しております。買い・売りのスプレッドは市場の自然スプレッドで極狭です。",
    p4: "ほかの取引所",
  },

  btns: {
    text1: "今すぐ口座開設",
    text2: "BTCCアプリをダウンロード",
  },
  step: {
    title: "30秒で取引を始められる",
    step1: "ダウンロード",
    step2: "口座開設",
    step3: "入金",
    step4: "取引",
    Download:"BTCCアプリをダウンロード"

},
  BTCC: {
    title: "BTCCお客様の声",
    p: "初心者からプロの投資家まで、幅広い層に支持されています",
    h4: "思ってたより使いやすいです。",
    p2: "あまり馴染みがない海外取引所なので使う前に心配してましたが、アプリが使いやすく、入出金もスムーズにできました。最初はサイト版使ってみましたが、アプリのほうが断然便利です。",
    head2: "のび",
    head3: "初心者に優しいアプリ",
    p3: "最近仮想通貨を初めたのですが、どこの取引所がいいか色々見てみました。取引手数料が低いことと、キャンペーンが豪華ということで口座開設してみたところ、BTCCは海外取引所の中でも日本語サポートが手厚く、また初心者ガイドとか学習に使えるコンテンツも多くて嬉しいです！最近は仮想通貨ではなかなか見ないデモ取引でお試し中～",
    head4: "Naomimi",
    head5: "海外取引所の中でも優れている",
    p4: "自分はバイビットとコインベース使っているが、BTCCのほうが取引コストが低くて使い始めてみました。手数料低い＋レバレッジが高い＝少額投資にピッタリ",
    head6: "月まで",
    h42: "SNSから入りました",
    p22: "最近インスタでBTCCを見て、ダウンロードしたものの、思いほか使いやすいです。インスタのコンテンツも充実しててフォローしました。初心者のわたしにとって学べる場所です。",
    head22: "仮想通貨女子",
    head32: "新参者？",
    p32: "聞いたことがない取引所だから使うかどうか迷いましたが、公式のLINEで色々見て安心しました。",
    head42: "gachiho",
    head52: "操作はバイナンスのと同じくらい",
    p42: "バイナンスのアプリ使ってたが、気になってダウンロードしてみました。コンテンツはバイナンスのほうがたくさんあるが、こっちも同じくらい使いやすかった。",
    head62: "ばいな",
    h43: "デモ取引口座あり！",
    p23: "色々試してみたいと思ったからデモ取引があるのは嬉しい！バーチャルマネー全部なくさないよう頑張る笑",
    head23: "test",
    head33: "悪くはない",
    p33: "意外と日本語が読みやすく、使いやすかったです。",
    head43: "r8t23",
    head53: "最初詐欺だと思いました",
    p43: "宝くじで1BTCも当てられると聞いて、騙されたとの思いでアプリをダウンロードしてみました。その日は結果5USDTしか当たらなかったが、毎日宝くじやっていつか1BTCをゲットしようと思います！",
    head63: "物欲センサー発動するな",
    btcc: "btcc",
    "mobileTip": "携帯電話番号を正確に入力してください",
    "emailTip": "メールアドレスを正確に入力してください",
    "codeTip": "認証コードを正確に入力してください",
    "pwdTip": "6~16文字の英数字のパスワード",
    "tips": "をよく読み、了承します",
    "tips1": "《サービス利用規約》",
    "tips2": "《プライバシーポリシー》",
    "tips3": "《リスク開示》",
    "tipslang": "https://www.btcc.com/detail/142641.html"
  }, 
    Industry: {
        title: "業界のトップニュース",
        industrynone:"industry-none",
        行業重點資訊: "業界注目ニュース",
        大公司:"ビットコインを受け入れている大手企業10社",
        雅虎財經:"ヤフーファイナンス",
        隨著科技巨頭:"テクノロジー大手やストリーミングプラットフォーム、フードチェーンが暗号資産を決済手段として採用し、ビットコインは国際通貨への道を歩み始めている。",
        比特幣比電匯便宜:"ビットコインは銀行振込の300倍安い、銀行は83％もの利益を",
        BTC新聞:"ニュース BTC",
        比特幣透過擴大:"ビットコインは、ブロックチェーンの取引容量を拡大することでスケーラビリティの課題を解決し、銀行振込よりもはるかに安い送金を実現してくれる。",
        薩爾瓦多:"エルサルバドル、ビットコインを法定通貨として初採用",
        華爾街日報:"ウォール・ストリート・ジャーナル",
        在經歷了比特幣:"技術的な問題でビットコインウォレットが使えなくなったこともあったが、エルサルバドル政府は、周辺各国からの投資を呼び込むために、ビットコインを法定通貨として採用することを発表した。",
        美國首個比特幣期貨:"米国初のビットコイン先物ETF、今週から取引開始へ",
        美國全國廣播公司財經頻道:"CNBC",
        Proshares:"プロシェアズ初のビットコイン先物ETFが今週取引を開始し、米国、世界の暗号資産産業の歴史を画する瞬間になった。"
    },
    Foot:{
		text:"リスクについての注意事項：仮想通貨取引は明るい展望を持つ新産業です。その価値は国によって保証されず、また急な変動で損をする可能性がございます。特にレバレッジ取引では、獲得可能な利益が増えた一方でリスクも大きくなります。ご利用の前に、仮想通貨業界、レバレッジ取引、取引ルールを十分に理解をするようにしてください。また、ご自身のリスクの範囲内で取引されることを強くお勧めします。取引にはリスクが伴います。市場に参入する際には、慎重に行動してください。",
		text2:"世界で最も古い取引所 安定運営10周年 © 2011-2022 BTCC.com. All rights reserved",
    服務:"サービス",
    永續合約:"永久契約",
    當週合約:"ウィークリー契約",
    當日合約:"デイリーコントラクト",
    支持:"サポート",
    最新公告:"最新のお知らせ",
    帮助中心:"ヘルプセンター",
    費率標準:"手数料基準",
    即時數據:"リアルタイムデータ",
    條款:"条項",
    服務條款:"サービス利用規約",
    隱私聲明:"プライバシーポリシー",
    風險提示:"リスク開示",
    客戶服務:"カスタマーサービス",
    在线客服:"オンラインカスタマーサービス", 
    社區:"コミュニティ",
    全球社區:"20万以上のユーザーがグローバルコミュニティに参加しています",
    快速鏈接:"クイックリンク",
    如何在BTCC買賣比特幣合約:"ビットコイン（BTC）先物を購入する",
    如何在BTCC買賣以太坊期貨:"イーサリアム（ETH）先物を購入する",
    如何在BTCC買賣萊特幣期貨:"ライトコイン（LTC）先物を購入する",
    如何在BTCC買賣BCH比特幣現金:"ビットコインキャッシュ（BCH）先物を購入する",
    如何在BTCC買賣EOS柚子幣期貨:"イーオーエス（EOS）先物を購入する",
    如何在BTCC買賣瑞波幣XRP永續合約:"リップル（XRP）先物を購入する",
    如何在BTCC買賣艾達幣ADA永續合約:"カルダノ（ADA）先物を購入する",
    如何在BTCC買賣恆星幣XLM永續合約:"ステラルーメン（XLM）先物を購入する",
    如何在BTCC買賣達世幣永續合約:"ダッシュ（DASH）先物を購入する",
    客服:"https://btccoverseas.s5.udesk.cn/im_client/?web_plugin_id=177&group_id=140&language=ja&platform=GW",
    telegram:"https://t.me/youtbtcc",
    weibo:"https://weibo.com/p/1006062149945883/home",
    youtube:"https://www.youtube.com/channel/UCtP3iL99jxLT4a3xmI2g6dQ",
    twitter:"https://twitter.com/BTCC_JP",
    facebook:"https://www.facebook.com/yourbtcc/",
    linkedin:"https://www.linkedin.com/company/yourbtcc/",
    instagram:"https://www.instagram.com/btcc_jp/",
    btccblog:"https://medium.com/btccblog",
    reddit:"https://www.reddit.com/r/YourBTCC/",
    比特幣合約:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-btc-futures-in-btcc",
        以太坊期貨:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-eth-futures-in-btcc",
        萊特幣期貨:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-ltc-futures-in-btcc",
        比特幣現金:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-bch-futures-in-btcc",
        柚子幣期貨:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-eos-futures-in-btcc",
        XRP永續合約:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-xrp-futures-in-btcc",
        ADA永續合約:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-ada-futures-in-btcc",
        XLM永續合約:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-xlm-futures-in-btcc",
        達世幣永續合約:"https://www.btcc.com/ja-JP/academy/crypto-basics/buy-dash-futures-in-btcc",
  },
  bonus:{
    注册免费获取:"登録して無料ボーナスをゲット",
    模拟账户:"デモ取引",
    轻松训练提升交易技能:"トレードスキルを簡単に学ぶ",
    注册获取模拟账户:"登録してデモ口座をゲット",
    如何获取模拟账户:"デモ口座の受け取り方",
    第一步注册账户:"ステップ1：会員登録",
    第二步下载APP:"ステップ2：アプリをダウンロードする",
    第三步APP登录:"ステップ3：ログイン",
    第四步切换模拟账户:"ステップ4：デモ口座への切替",
    第二步进入交易界面:"ステップ2：取引画面に入る",
    第三步账号登录:"ステップ3：ログイン",
    新用户专享豪礼:"新規ユーザー限定イベント",
    "14000USDT":"14000USDT",
    注册后7天可领取:"登録後7日以内の入金でボーナスプレゼント",
    立即注册领取:"今すぐ登録してボーナスをゲット",
    如何领取新用户豪礼:"ボーナスの受け取り方",
    第二步下载官方APP:"ステップ2：アプリをダウンロードする",
    第三步用注册的账户登录:"ステップ3：ログイン",
    第四步APP内报名领取奖励:"ステップ4：イベントへの参加登録",
    第二步进入官网:"ステップ2：公式サイトへアクセス",
    第三步用注册的账户登录:"ステップ3：登録したアカウントでログイ",
    第四步官网内报名领取奖励:"ステップ4：公式サイト内で特典を申し込む",
},
bonus2:{
  googleplay:"/JP_google.svg",
  appstore:"/JP_Apple.svg",
  p1:"BTCCの手数料は他の取引所と比べ非常に低く、隠れた費用もなく、安心して利用できます。",
  title:"業界最低水準の手数料",
  p2:"取引手数料は最低わずか0.03％、取引コストを気にしないで快適な取引ができます。",
  p3:"リアルタイムの価格を提供し、狭いスプレッドで取引することができます。",
  text:"≪暗号資産を利用する際の注意点≫",
  text2:"暗号資産は、日本円やドルなどのように国がその価値を保証している「法定通貨」ではありません。インターネット上でやりとりされる電子データです。<br/>,暗号資産は、価格が変動することがあります。暗号資産の価格が急落し、損をする可能性があります。<br/>暗号資産交換業者は金融庁・財務局への登録が必要です。利用する際は登録を受けた事業者か金融庁・財務局のホームページで確認してください。<br/>暗号資産の取引を行う場合、事業者が金融庁・財務局から行政処分を受けているかを含め、取引内容やリスク（価格変動リスク、サイバーセキュリティリスク等）について、利用しようとする事業者から説明を受け、十分に理解するようにしてください。<br/>暗号資産や詐欺的なコインに関する相談が増えています。暗号資産の持つ話題性を利用したり、暗号資産交換業の導入に便乗したりする詐欺や悪質商法にご注意ください。",
  text3:"BTCC 暗号資産取引における重要事項について",
  text4:"・暗号資産は、代価の弁済を受ける者の同意がある場合に限り代価の弁済のために使用することができます。<br/>・本取引は、暗号資産(暗号資産)を売買する際の売買価格差があり、相場急変時や流動性の低下時等は、売買価格差が広がることや、注文受付を中断するなどにより、意図した取引ができない可能性があります。<br/>・本取引の取引システム又は当社とお客様を結ぶ通信回線等が正常に作動しないことにより、処理の遅延や、注文の発注、執行、確認及び取消し等が行えない可能性があります。<br/>・暗号資産は、サイバー攻撃等により、全部又は一部を消失する可能性があります。<br/>・外部環境の変化（暗号資産に対する法規制の強化を含みます。）、当社の財務状況の悪化、委託先等の破綻等によって、当社の事業が継続できなくなる可能性があります。当社が破綻した場合には、お客様の資産を返還することができなくなり、お客様に損失が生じる可能性があります。<br/>・本取引では、取引手数料その他の手数料を頂戴いたします。<br/>・当社は新規通貨を取り扱うにあたり、通貨発行元その他利害関係者より直接もしくは間接的に上場報酬として当該通貨もしくは法定通貨を受領する可能性があります。<br/>・資金決済法の改正（2020年5月1日施行）により、法令上、「仮想通貨」は「暗号資産」へ呼称変更されましたが、当社ウェブサイト内では、「仮想通貨」と一部表記しております。",
},
account:{
  最短:"最短 10 分で口座開設",
  本人確認:"※本人確認を行い、本人確認資料をご提出いただいてから、お手続き完了までの時間",
  p1:"1. メールアドレス登録",
  span1:"メールアドレスを入力し、確認メールを受け取ります。",
  p2:"2. 本人確認",
  span2:"BTCCアプリをダウンロードし、本人確認を行います。",
  p3:"3. 入金・取引",
  span3:"ウォレットに入金し、素早く仮想通貨を取引することができます。",

},
strategy:{
  交易攻略:"トレード戦略",
  突發:"速報! ビットコイン急落、底値は？",
  加密怪獸:"BTCCアカデミー",
  目前來講:"4時間足チャートで見て急落したばかりだと考えられます。突き抜けるように落ちていますが、すぐにショートを狙うのはおすすめできません。 ショートを狙うなら、このロウソク足が陰線の終値を割り込んだあとに検討しましょう。",
  如何投資:"ビットコインなどの暗号資産の投資方法",
  BTCC學院:"BTCCアカデミー",
  比特幣:"ビットコインは最も人気のある暗号資産として知られていますが、ビットコインのほかにも多くの人気コインがあります。 ポートフォリオの多様性を考えて、暗号資産を購入する場合、他の暗号資産についても検討することをおすすめします。",
  基本分析:"ファンダメンタル分析 vs テクニカル分析",
  BTCC學院1:"BTCCアカデミー",
  評估:"ファンダメンタル分析は経済・財務の関連要因を検討し、企業の本質的な価値を測定する手法で、アナリストは経済や業界などのマクロ経済的な要因から、企業の管理のミクロ的な要因まで幅広く分析しています。これに対し、テクニカル分析（TA）は、過去の値動きや出来高をもとに、将来の値動きを予測するもので、通常、陰線・陽線のローソク足や移動平均線・取引高などのテクニカル指標を使用します。",
  什麼是加密貨幣:"暗号資産取引におけるレバレッジとは？",
  BTCC學院2:"BTCCアカデミー",
  在加密貨幣交易中:"暗号資産取引において、レバレッジとは、小さな金額から大きな資金を借りて取引を行うことです。 レバレッジを効かせることで、より大きな金額で取引を可能にし、つまりより大きな利益を狙えることができます。 レバレッジをかけると、少額から投資ができます。",
},
lottery:{
  幸运转大奖:"毎日当たる宝くじ",
  每日送1BTCETH:"毎日1BTC & ETH ",
  "10000USDTXRP":"10,000USDT & XRPをプレゼント",
  活动规则:"開催概要：",
  注册账户后并下载手机APP参与活动期间每天从:"キャンペーン期間中、毎日",
  "8点":"01:00:00（日本時間）",
  开始宝箱倒计时结束即可抽奖:"より、宝箱のカウントダウンが終了するたびに、アプリで1回宝くじを引くことができます。1日最大",
  抽奖仅3次:"3回",
  次日8点之前未抽奖的机会将失效 :"の抽選が可能です。未使用の抽選チャンスは、毎日の01:00:00（日本時間）に自動的に無効になります。一等は",
  "比特币(BTC)":"1BTC（約500万円相当）を獲得できます！",
  "10000USDT模拟账户":"10,000 USDT デモ口座",
  BTCC为您准备了高达:"BTCCでは、デモ取引をご体験いただきますように、",
  "10000USDT":"10,000USDT",
  的模拟资金供您体验模拟交易:"のデモ資金を提供いたします。 これにより、マーケットや取引ルールを素早く理解し、実際の取引でより早く利益を上げることができるようになります。",
  "title": "クレジットカードで仮想通貨を購入しましょう！",
  "li1": "2011年に設立されたBTCCは、世界運営時間No.1の取引所です",
},
coupon:{
  充就送10USDT:"入金すると 10USDT ゲットできる",
  "0.003BTC以上":"・BTCの入金額 ≧0.003BTC・ETHの入金額≧0.04ETH・XRPの入金額≧100XRP・USDT、日本円の入金額≧100USDT",
  注册账户后在活动期间充值:"キャンペーン期間中に、次の条件のいずれかを満たすと",
  "100USDT以上":"10USDT ",
  即可获得10USDT赠金:"を獲得できます。",
  活动规则:"開催概要：",
  "每个账户":"※各アカウントは",
  仅限领取1次:"1回",
  领取后立即到账BTCC账户:"のみ10USDTを獲得することができます。ボーナスは入金する後すぐにアカウントはBTCCの口座に配布されます。ボーナスは取引時の手数料、ファンディング手数料と取引による損失、補填に使用できます。",
},
rebate:{
  "交易返现":"取引でキャッシュバック",
  "500USDT":"500USDT還元",
  注册账户后:"開催概要：口座登録後、キャンペーン期間中に発生した手数料の15％",
  "500USDT":"（最大500USDT）", 
  text:"が取引翌日から24時間以内に還元されます。"
},
    countryList: {
        "ISO_3166_716": "ジンバブエ",
        "ISO_3166_074": "ブーベ島",
        "ISO_3166_466": "マリ",
        "other": "その他",
        "ISO_3166_072": "ボツワナ",
        "ISO_3166_096": "ブルネイ",
        "ISO_3166_100": "ブルガリア",
        "ISO_3166_854": "ブルキナファソ",
        "ISO_3166_108": "ブルンジ",
        "ISO_3166_120": "カメルーン",
        "ISO_3166_124": "カナダ",
        "ISO_3166_140": "中央アフリカ共和国",
        "ISO_3166_152": "チリ",
        "ISO_3166_162": "クリスマス島",
        "ISO_3166_166": "ココス諸島",
        "ISO_3166_170": "コロンビア",
        "ISO_3166_756": "スイス",
        "ISO_3166_178": "コンゴ",
        "ISO_3166_184": "クック諸島",
        "ISO_3166_188": "コスタリカ",
        "ISO_3166_384": "コートジボワール",
        "ISO_3166_192": "キューバ",
        "ISO_3166_196": "キプロス",
        "ISO_3166_203": "チェコ共和国",
        "ISO_3166_208": "デンマーク",
        "ISO_3166_262": "ジブチ",
        "ISO_3166_276": "ドイツ",
        "ISO_3166_012": "アルジェリア",
        "ISO_3166_218": "エクアドル",
        "ISO_3166_818": "エジプト",
        "ISO_3166_732": "西サハラ",
        "ISO_3166_724": "スペイン",
        "ISO_3166_233": "エストニア",
        "ISO_3166_246": "フィンランド",
        "ISO_3166_250": "フランス",
        "ISO_3166_583": "ミクロネシア",
        "ISO_3166_266": "ガボン",
        "ISO_3166_226": "赤道ギニア",
        "ISO_3166_254": "ギアナ",
        "ISO_3166_270": "ガンビア",
        "ISO_3166_239": "ジョージア",
        "ISO_3166_288": "ガーナ",
        "ISO_3166_292": "ジブラルタル",
        "ISO_3166_300": "ギリシャ",
        "ISO_3166_304": "グリーンランド",
        "ISO_3166_826": "イギリス",
        "ISO_3166_308": "グリーンウッド",
        "ISO_3166_312": "グアドループ",
        "ISO_3166_316": "グアム",
        "ISO_3166_320": "グアテマラ",
        "ISO_3166_624": "ギニアビサウ",
        "ISO_3166_328": "ガイアナ",
        "ISO_3166_191": "クロアチア",
        "ISO_3166_332": "ハイチ",
        "ISO_3166_340": "ホンジュラス",
        "ISO_3166_348": "ハンガリー",
        "ISO_3166_352": "アイスランド",
        "ISO_3166_356": "インド",
        "ISO_3166_360": "インドネシア",
        "ISO_3166_364": "イラン",
        "ISO_3166_368": "イラク",
        "ISO_3166_086": "インド洋連邦",
        "ISO_3166_372": "アイルランド",
        "ISO_3166_376": "イスラエル",
        "ISO_3166_380": "イタリア",
        "ISO_3166_388": "ジャマイカ",
        "ISO_3166_392": "日本",
        "ISO_3166_400": "ヨルダン",
        "ISO_3166_398": "カザフスタン",
        "ISO_3166_214": "ドミニカ",
        "ISO_3166_404": "ケニア",
        "ISO_3166_296": "キリバス",
        "ISO_3166_408": "北朝鮮",
        "ISO_3166_410": "韓国",
        "ISO_3166_116": "カンボジア",
        "ISO_3166_174": "コモロ",
        "ISO_3166_414": "クウェート",
        "ISO_3166_417": "キルギス",
        "ISO_3166_136": "ケイマン諸島",
        "ISO_3166_418": "ラオス",
        "ISO_3166_144": "スリランカ",
        "ISO_3166_428": "ラトビア",
        "ISO_3166_422": "レバノン",
        "ISO_3166_426": "レソト",
        "ISO_3166_430": "リベリア",
        "ISO_3166_434": "リビア",
        "ISO_3166_438": "リヒテンシュタイン",
        "ISO_3166_440": "リトアニア",
        "ISO_3166_442": "ルクセンブルク",
        "ISO_3166_450": "マダガスカル",
        "ISO_3166_454": "マラウイ",
        "ISO_3166_458": "マレーシア",
        "ISO_3166_462": "モルディブ",
        "ISO_3166_470": "マルタ",
        "ISO_3166_584": "マーシャル諸島",
        "ISO_3166_478": "モーリタニア",
        "ISO_3166_480": "モーリシャス",
        "ISO_3166_484": "メキシコ",
        "ISO_3166_498": "モルドバ",
        "ISO_3166_492": "モナコ",
        "ISO_3166_496": "モンゴル",
        "ISO_3166_504": "モロッコ",
        "ISO_3166_508": "モザンビーク",
        "ISO_3166_104": "ミャンマー",
        "ISO_3166_516": "ナミビア",
        "ISO_3166_520": "ナウル",
        "ISO_3166_524": "ネパール",
        "ISO_3166_528": "オランダ",
        "ISO_3166_540": "ニューカレドニア",
        "ISO_3166_554": "ニュージーランド",
        "ISO_3166_558": "ニカラグア",
        "ISO_3166_562": "ニジェール",
        "ISO_3166_566": "ナイジェリア",
        "ISO_3166_570": "ニウエ",
        "ISO_3166_578": "ノルウェー",
        "ISO_3166_512": "オマーン",
        "ISO_3166_586": "パキスタン",
        "ISO_3166_260": "仏領ポリネシア",
        "ISO_3166_585": "パラオ",
        "ISO_3166_591": "パナマ",
        "ISO_3166_598": "パプアニューギニア",
        "ISO_3166_600": "パラグアイ",
        "ISO_3166_604": "ペルー",
        "ISO_3166_608": "フィリピン",
        "ISO_3166_612": "ピトケアン諸島",
        "ISO_3166_616": "ポーランド",
        "ISO_3166_620": "ポルトガル",
        "ISO_3166_630": "プエルトリコ",
        "ISO_3166_634": "カタール",
        "ISO_3166_642": "ルーマニア",
        "ISO_3166_643": "ロシア連邦",
        "ISO_3166_646": "ルワンダ",
        "ISO_3166_222": "エルサルバドル",
        "ISO_3166_654": "セントヘレナ",
        "ISO_3166_674": "サンマリノ",
        "ISO_3166_678": "サントメ・プリンシペ",
        "ISO_3166_682": "サウジアラビア",
        "ISO_3166_686": "セネガル",
        "ISO_3166_690": "セーシェル",
        "ISO_3166_694": "シエラレオネ",
        "ISO_3166_702": "シンガポール",
        "ISO_3166_703": "スロバキア",
        "ISO_3166_705": "スロベニア",
        "ISO_3166_090": "ソロモン諸島",
        "ISO_3166_706": "ソマリア",
        "ISO_3166_736": "スーダン",
        "ISO_3166_740": "スリナム",
        "ISO_3166_748": "スワジランド",
        "ISO_3166_752": "スウェーデン",
        "ISO_3166_760": "シリア",
        "ISO_3166_810": "（旧）ソビエト連邦",
        "ISO_3166_148": "チャド",
        "ISO_3166_762": "タジキスタン",
        "ISO_3166_834": "タンザニア",
        "ISO_3166_764": "タイ",
        "ISO_3166_768": "トーゴ",
        "ISO_3166_776": "トンガ",
        "ISO_3166_780": "トリニダード・トバゴ",
        "ISO_3166_788": "チュニジア",
        "ISO_3166_792": "トルコ",
        "ISO_3166_626": "東ティモール",
        "ISO_3166_795": "トルクメニスタン",
        "ISO_3166_798": "ツバル",
        "ISO_3166_800": "ウガンダ",
        "ISO_3166_804": "ウクライナ",
        "ISO_3166_840": "アメリカ",
        "ISO_3166_858": "ウルグアイ",
        "ISO_3166_860": "ウズベキスタン",
        "ISO_3166_336": "バチカン市国",
        "ISO_3166_862": "ベネズエラ",
        "ISO_3166_704": "ベトナム",
        "ISO_3166_882": "西サモア",
        "ISO_3166_887": "イエメン",
        "ISO_3166_891": "ユーゴスラビア",
        "ISO_3166_710": "南アフリカ",
        "ISO_3166_180": "ザイール",
        "ISO_3166_894": "ザンビア",
        "ISO_3166_156": "中国",
        "ISO_3166_158": "台湾",
        "ISO_3166_446": "マカオ",
        "ISO_3166_344": "香港",
        "ISO_3166_004": "アフガニスタン",
        "ISO_3166_008": "アルバニア",
        "ISO_3166_020": "アンドラ",
        "ISO_3166_024": "アンゴラ",
        "ISO_3166_010": "南極大陸",
        "ISO_3166_028": "アンティグア・バーブーダ",
        "ISO_3166_032": "アルゼンチン",
        "ISO_3166_051": "アルメニア",
        "ISO_3166_533": "アルバ",
        "ISO_3166_036": "オーストラリア",
        "ISO_3166_040": "オーストリア",
        "ISO_3166_031": "アゼルバイジャン",
        "ISO_3166_784": "アラブ首長国連邦",
        "ISO_3166_044": "バハマ",
        "ISO_3166_048": "バーレーン",
        "ISO_3166_050": "バングラデシュ",
        "ISO_3166_052": "バルバドス",
        "ISO_3166_112": "ベラルーシ",
        "ISO_3166_084": "ベリーズ",
        "ISO_3166_056": "ベルギー",
        "ISO_3166_204": "ベナン",
        "ISO_3166_060": "バミューダ",
        "ISO_3166_064": "ブータン",
        "ISO_3166_068": "ボリビア",
        "ISO_3166_070": "ボスニア・ヘルツェゴビナ",
        "ISO_3166_076": "ブラジル",
        "ISO_3166_772": "トケラウ",
        "ISO_3166_324": "ギニア",
        "ISO_3166_231": "エチオピア",
        "ISO_3166_242": "フィジー",
        "ISO_3166_238": "フォークランド諸島",
    },
    "已有账户？":"アカウントをお持ちですか？ ",
      "立即登录":"今すぐログインする",
      "立即注册":"今すぐ口座開設",
      "发送验证码成功":"認証コード送信完了！認証コードメールが届かない場合、迷惑メールフォルダをご確認お願いいたします。",
      search: "検索",
      
}