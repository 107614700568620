export const en = {
  login: {
    "App Store 下载": "App Store",
    "Google Play 下载": "Google Play",
    注册: "Register Now",
    title: "Buy Crypto with <br/>Visa/Master Credit Card!",
    title2: "14000USDT",
    title3: "Exclusive for New Users",
    li1: "Founded in 2011, BTCC is the world's longest-running <br/> crypto exchange",
    li1_1: "Founded in 2011, BTCC is the world's longest-running crypto exchange",
    li2: "US MSB and CA MSB licensed",
    li3: "18+ cryptocurrencies available ",
    li4: "Allows for deposits in 9+ fiat currencies",
    li5: "Trade with 1 - 150 leverage ",
    li6: "Meet the deposit targets within 7 days after successful registration at BTCC, and you can enjoy the bonus of the corresponding target levels.",
    tab1: "Email ",
    email: "Please enter your email address",
    code: "Please enter the verification code",
    Send: "Get code",
    pwd: "Password must be 6–16 characters containing both numbers and letters.",
    tab2: "Mobile",
    mobile: "Please enter your mobile number",
    Register: "Register Now",
    服务条款: "https://www.btcc.com/en-US/detail/142641",
    隐私声明: "https://www.btcc.com/en-US/detail/142638",
    风险提示: "https://www.btcc.com/en-US/detail/149036",
    and: "and",
    "首页url":"https://lp.btcc.academy/en-GB",
    "首页":"Home",
    "关于我们":"About Us",
    "about":"en-GB/about-us.html",
    "about_in":"about_in-us.html",
    "about_dm":"about_dm-us.html",
    "about_dms":"download/about_dm-kr.html",
    "about_coupon":"about_coupon-us.html",
    "about_rebate":"about_rebate-us.html",
    "about_lottery":"about_lottery-us.html",

    mobileTip: "Please enter a valid mobile number",
    emailTip: "Please enter a valid email address",
    codeTip: "Please enter a valid verification code",
   pwdTip:
      "Password must be 8-16 characters long with both uppercase and lowercase letters and numbers special characters.",
    tips: "I have read and agree to BTCC's",
    tips1: "《User agreement》",
    tips2: "《Privacy policy》",
    tips3: "《Risk Disclosure》",

    registeSuccessInfo: "Thank you for your registration. ",
    startTrading: "Start trading now!",
    registeSuccessInfoPhone: "Thank you for your registration. ",
    startTradingPhone: "Start trading now!",
    注册成功:"Registration successful",
    下载应用:"Download the app to get your <br/><span>10 USDT</span> bonus!",
    立即下载:"Download now"
  },
  ad_sign_up:{
    邮箱注册:"Email Registration",
    电子邮箱:"Email address",
    验证码:"Verification code",
    获取验证码:"Get Verification Code",
    设置密码:"Create a password",
    立即注册:"Register now",
    手机注册:"Mobile Registration",
    手机号码:"Phone number",
      tips1: "《User agreement》",
      tips2: " 《Privacy Agreement》",
      tips3: " 《Risk Disclosure》",
  },

  BitcoinHalvingBTCC: {
    与BTCC一起见证比特币减半: "Witness the Bitcoin Halving<br/> with BTCC <h1>Grab a share of<br/> 13 BTC</h1>",
    活动时间: "Campaign period: From March 1, 2024, 02:00:00 (UTC) until the Bitcoin block height reaches over 840,000",
    当前区块高度: "Current Block Height",
    剩余区块: "Blocks Remaining",
    减半区块: "Halving Block Height",
    预估减半时间: "Estimated Halving Date",
    立即报名: "Register now",
    已报名: "Registered",
    统计发奖中: "Campaign ended. Distributing rewards    ",
    用户获得: "User{{uid}} earned<span> {{awardAmount}} SATS</span>",
    比例膨胀: "As the Bitcoin block height reaches the following milestones, the reward amount in your account will grow according to the corresponding rates:  ",
    区块高度: "Block Height Milestone",
    膨胀比例: "Reward Growth Rate  ",
    膨胀奖励计算方法: "An example on how rewards grow: For instance, when the Bitcoin block height reaches 835,000, User A's already earned 10,000 SATS reward will grow to 10,200 SATS (10,000 SATS × 1.02). When the block height reaches 836,000, the reward will grow to 10,710 SATS (10,200 SATS × 1.05).",
    当前我获得的奖励: "Current Reward Earned",
    换算器: "SATS/BTC/USDT Converter",
    活动奖励: "Campaign Rewards",
    签到奖: "Check-in Reward",
    每日签到: "Earn 100 SATS for each daily check-in.",
    去签到: "Check in now",
    已签到: "Checked in today",
    交易奖: "Trading Reward",
    每交易: "Earn 500 SATS for every 10,000 USDT traded.",
    不满: "(Trade volume of less than 10,000 USDT will not be calculated for rewards. Each participant can earn a maximum of 10,000,000 SATS.)  ",
    去交易: "Trade now",
    充值奖: "Deposit Reward",
    每充值: "Earn 10,000 SATS for every 1,000 USDT deposited. ",
    不满1000: "(Deposits of less than 1,000 USDT will not be calculated for rewards. Each participant can earn a maximum of 10,000,000 SATS.)",
    去充值: "Deposit now",
    邀请奖: "Referral Reward  ",
    邀请好友: "Earn 10,000 SATS for each friend you invite who completes their first deposit.",
    每人最多: "(Each participant can earn a maximum of 10,000,000 SATS.)  ",
    去邀请: "Invite now",
    问答奖: "Quiz Reward",
    参与知识问答: "Participate in quizzes to earn 1,000 SATS per completed quiz. ",
    每当区块高度: "Unlock a quiz opportunity each time the Bitcoin block height reaches a milestone.  ",
    去答题: "Take a quiz  ",
    已完成: "Quiz completed",
    一题: "The Bitcoin halving this year is the _______ halving in history.  ",
    第二次: "second",
    第三次: "third",
    第五次: "fifth",
    第四次: "fourth",
    二题: `What does the "halving" in Bitcoin halving refer to? `,
    比特幣的價值減半: "The value of Bitcoin being halved",
    比特幣持有人數的減半: "The number of Bitcoin holders being halved",
    比特幣總量的減半: "The total quantity of Bitcoins being halved  ",
    比特幣區塊獎勵的減半: "The reward for Bitcoin mining being halved",
    三题: "What are the founding year of BTCC and the year of the first Bitcoin halving, respectively?",
    三A: "2012、2012",
    三B: "2012、2011",
    三C: "2011、2011",
    三D: "2011、2012",
    四题: "Which of the following is(are) the effect(s) of the Bitcoin halving?",
    供應速度減慢: "A slowdown in the rate at which new bitcoins are created",
    比特幣礦工利潤變動: "Changes in Bitcoin miner profits",
    比特幣礦工更加競爭: "Increased competition among Bitcoin miners",
    以上皆是: "All of the above",
    五题: `What is the social media hashtag for the "BTCC x Bitcoin" campaign? `,
    BTCCxBitcoin: "#BTCCxBitcoin",
    BTCCBitcoin: "#BTCC&Bitcoin",
    BTCONBTCC: "#BTCONBTCC",
    BitcoinOnBTCC: "#BitcoinOnBTCC",
    排行榜: "Leaderboard",
    排行榜排名: "The leaderboard ranks participants based on the amount of rewards earned. The top 10 participants will receive <span>100,000</span> SATS each, and those ranking 11th to 50th will receive <span>10,000</span> SATS each. The leaderboard shows only the top 10 participants.",
    我的排名: "My Ranking:",
    未上榜: "Not on list  ",
    排名: "Ranking",
    用户UID: "User ID  ",
    奖励金额: "Reward Amount (SATS)  ",
    null:"Not on list",
    0: "Not on list",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    14: "14",
    15: "15",
    16: "16",
    17: "17",
    18: "18",
    19: "19",
    20: "20",
    21: "21",
    22: "22",
    23: "23",
    24: "24",
    25: "25",
    26: "26",
    27: "27",
    28: "28",
    29: "29",
    30: "30",
    31: "31",
    32: "32",
    33: "33",
    34: "34",
    35: "35",
    36: "36",
    37: "37",
    38: "38",
    39: "39",
    40: "40",
    41: "41",
    42: "42",
    43: "43",
    44: "44",
    45: "45",
    46: "46",
    47: "47",
    48: "48",
    49: "49",
    50: "50",
    进行排名: "*Tiebreakers for equal reward amounts will be determined by the order of registration, from earliest to latest.",
    活动规则: "Campaign Rules",
    规则1: "1. Registration is required to participate, and only users who have completed KYC verification are eligible.",
    规则2: "2. The campaign ends when the Bitcoin block height reaches 840,000.",
    规则3: "3. If a user registers after a block height milestone has passed, rewards will not grow based on the growth rate of that milestone. Participants must wait for the next milestone.  ",
    规则4: "4. A quiz opportunity is unlocked at each block height milestone reached. If the previous quiz is not completed before the next one is unlocked, the previous quiz will expire.",
    规则5: "5. Campaign rewards will ultimately be converted and distributed in USDT.",
    规则6: "6. The actual distributed rewards will be in the form of trading funds, with the actual amount based on the BTC price at the time of settlement.  ",
    规则7: "7. Reward amounts will be settled and distributed within 7 business days after the campaign ends.",
    规则8: "8. BTCC reserves the right to disqualify participants who engage in malicious behavior to profit, including but not limited to opening multiple accounts for additional trading funds, reusing on-chain funds, and other suspected illegal, fraudulent, or harmful actions.",
    规则9: "9. BTCC holds the final interpretation right of this campaign. We reserve the right to adjust the content and rewards of the campaign according to market conditions without notifying users. For any questions, please contact our customer service team.  ",
    回答错误: "Incorrect answer. Please try again.  ",
    提交答案: "Submit answer",
    请先完成报名: "Please register first",
    请先完成KYC: "Please complete KYC first  ",
    签到成功: "Successful check-in +100 SATS",
    登录查看: "Log in to view",
    回答正确: " Correct answer +1,000 SATS",

  },

  bwtc:{
    "bwtc_title": "BTCC World Trading Competition 2024",
    "bwtc_slogan": "Total Prize Pool: 10,000,000 USDT",
    "bwtc_share": "Share",
    "bwtc_join": "Register now",
    "bwtc_personal_title": "Individual Trading Competition",
    "bwtc_personal_desc": "Compete and share a prize pool of 1,400,000 USDT. Each participant can earn rewards of up to 26,700 USDT per month!",
    "bwtc_pool_daily": "Daily Prize Pool",
    "bwtc_pool_week": "Weekly Prize Pool",
    "bwtc_pool_month": "Monthly Prize Pool",
    "bwtc_pool_start": "Time until prize pool opens: {{day}}d {{hour}}h {{minute}}m {{second}}s",
    "bwtc_peroid": "Round {{amount}}",
    "bwtc_status_inprogress": "Ongoing",
    "bwtc_status_over": "Concluded",
    "bwtc_total_tradeamount": "Total Trading Volume (USDT)",
    "bwtc_lock_amount": "Unlocked Prize Pool (USDT)",
    "bwtc_pool": "Prize Pool (USDT)",
    "bwtc_login": "<b>Log in</b> to view my rewards",
    "bwtc_my_ranking": "My Ranking",
    "bwtc_my_daily_tradeamount": "My Trading Volume of the Day",
    "bwtc_my_week_tradeamount": "My Trading Volume of the Week",
    "bwtc_my_month_tradeamount": "My Trading Volume of the Month",
    "bwtc_my_awardamount": "Estimated Reward",
    "bwtc_ranking_title": "Leaderboard",
    "bwtc_prize_pool": "Prize pool in real time",
    "bwtc_ranking": "Ranking",
    "bwtc_uid": "UID",
    "bwtc_tradeamount": "Trading volume (USDT)",
    "bwtc_awardamount": "Estimated reward (USDT)",
    "bwtc_update_frequency": "Updated every 1 hours",
    "bwtc_rules_title": "Rules",
    "bwtc_daily_rules_desc": "Daily Prize Pool: Initial pool of 100 USDT, with an additional 100 USDT unlocked for every 1,000,000 USDT traded by all participants, up to a maximum of 5,000 USDT.",
    "bwtc_week_rules_desc": "Weekly Prize Pool: Initial pool of 400 USDT, with an additional 400 USDT unlocked for every 4,000,000 USDT traded by all participants, up to a maximum of 15,000 USDT.",
    "bwtc_month_rules_desc": "Monthly Prize Pool: Initial pool of 1,000 USDT, with an additional 1,000 USDT unlocked for every 10,000,000 USDT traded by all participants, up to a maximum of 30,000 USDT.",
    "bwtc_pool_end": "Time until the competition ends: {{day}}d {{hour}}h {{minute}}m {{second}}s",
    "bwtc_registered": "Registered",
    "bwtc_join_success": "Registration successful",
    "bwtc_submit": "Confirm",
    "bwtc_join_fail": "Registration failed. Please refresh and try again.",
    "bwtc_isee": "Got it",
    "bwtc_coming_soon": "The event is about to start, so stay tuned",
    "bwtc_individual": "Individual",
    "bwtc_copytrading": "Copy Trading",
    "bwtc_referral": "Referral",
    "bwtc_share_title": "Join me in sharing a $10M USDT prize pool! Prizes are available daily. Also, a limited-time 10,055 USDT welcome reward is now up for grabs!",
    "bwtc_share_qrcode": "Scan to view campaign details"
  },
  
  RegisterThirdParty:{
    从注册开始: 'Create Your Account',
    邮箱注册: 'Email',
    输入电子邮箱: 'Please enter your email address',
    设置登录密码: 'Set up a password',
    手机注册: 'Mobile',
    手机号: 'Mobile',
    邮箱: 'Email',
    密码: 'Password',
    邀请码: 'Referral code (optional)',
    下一步: "Next",
    协议: "As you proceed to complete your registration, you agree to our User Agreement,Privacy Policy and Risk Disclosure We will never divulge any of your personal information without your prior consent.",
    綁定電子信箱: "Bind your email address",
    signup: "Sign Up",
    NextStep:"Next Step",
  },

  signup: {
    邮箱注册: "Email Registration",
    手机注册: "Mobile Registration",
    电子邮箱: "Email Address",
    手机号码: "Cell Phone Number",
    验证码: "Verification Code",
    获取验证码: "Get Verification Code",
    设置密码: "Set Password",
    立即注册: "Register Now ",
    我已阅读并同意BTCC的: "I have read and agree with"
  },

  countryList: {
    ISO_3166_704: "Vietnam",
    ISO_3166_156: "China",
    ISO_3166_344: "Hong Kong (China)",
    ISO_3166_158: "Taiwan",
    ISO_3166_446: "Macau",
    ISO_3166_004: "Afghanistan",
    ISO_3166_008: "Albania",
    ISO_3166_020: "Andorra",
    ISO_3166_024: "Angola",
    ISO_3166_010: "Antarctica",
    ISO_3166_028: "Antigua and Barbuda",
    ISO_3166_032: "Argentina",
    ISO_3166_051: "Armenia",
    ISO_3166_533: "Aruba",
    ISO_3166_036: "Australia",
    ISO_3166_040: "Austria",
    ISO_3166_031: "Azerbaijan",
    ISO_3166_784: "United Arab Emirates",
    ISO_3166_044: "Bahamas",
    ISO_3166_048: "Bahrain",
    ISO_3166_050: "Bangladesh",
    ISO_3166_052: "Barbados",
    ISO_3166_112: "Belarus",
    ISO_3166_084: "Belize",
    ISO_3166_056: "Belgium",
    ISO_3166_204: "Benin",
    ISO_3166_060: "Bermuda Islands",
    ISO_3166_064: "Bhutan",
    ISO_3166_068: "Bolivia",
    ISO_3166_070: "Bosnia and Herzegovina",
    ISO_3166_072: "Botswana",
    ISO_3166_076: "Brazil",
    ISO_3166_096: "Brunei",
    ISO_3166_100: "Bulgaria",
    ISO_3166_854: "Burkina Faso",
    ISO_3166_108: "Burundi",
    ISO_3166_120: "Cameroon",
    ISO_3166_124: "Canada",
    ISO_3166_140: "Central African Republic",
    ISO_3166_152: "Chile",
    ISO_3166_162: "Christmas Island",
    ISO_3166_166: "Coco Islands",
    ISO_3166_170: "Colombia",
    ISO_3166_756: "Switzerland",
    ISO_3166_178: "Congo",
    ISO_3166_184: "Cook Island",
    ISO_3166_188: "Costa Rica",
    ISO_3166_384: "Ivory Coast",
    ISO_3166_192: "Cuba",
    ISO_3166_196: "Cyprus",
    ISO_3166_203: "Czech Republic",
    ISO_3166_208: "Denmark",
    ISO_3166_262: "Djibouti",
    ISO_3166_276: "Germany",
    ISO_3166_214: "Dominican Republic",
    ISO_3166_012: "Algeria",
    ISO_3166_218: "Ecuador",
    ISO_3166_818: "Egypt",
    ISO_3166_732: "Western Sahara",
    ISO_3166_724: "Spain",
    ISO_3166_233: "Estonia",

    ISO_3166_231: "Ethiopia",
    ISO_3166_242: "Fiji",
    ISO_3166_238: "Falkland Islands",
    ISO_3166_246: "Finland",
    ISO_3166_250: "France",
    ISO_3166_583: "Micronesia",
    ISO_3166_266: "Gabon",
    ISO_3166_226: "Equatorial Guinea",
    ISO_3166_254: "French Guyana",
    ISO_3166_270: "Gambia",
    ISO_3166_239: "Georgia",
    ISO_3166_288: "Ghana",
    ISO_3166_292: "Gibraltar",
    ISO_3166_300: "Greece",
    ISO_3166_304: "Greenland",
    ISO_3166_826: "United Kingdom",
    ISO_3166_308: "Grenada",
    ISO_3166_312: "Guadeloupe",
    ISO_3166_316: "Guam",
    ISO_3166_320: "Guatemala",
    ISO_3166_324: "Guinea",
    ISO_3166_624: "Guinea Bissau",
    ISO_3166_328: "Guyana",
    ISO_3166_191: "Croatia",
    ISO_3166_332: "Haiti",
    ISO_3166_340: "Honduras",
    ISO_3166_348: "Hungary",
    ISO_3166_352: "Iceland",
    ISO_3166_356: "India",
    ISO_3166_360: "Indonesia",
    ISO_3166_364: "Iran",
    ISO_3166_368: "Iraq",
    ISO_3166_086: "British Indian Ocean Territory",
    ISO_3166_372: "Ireland",
    ISO_3166_376: "Israel",
    ISO_3166_380: "Italy",
    ISO_3166_388: "Jamaica",
    ISO_3166_392: "Japan",
    ISO_3166_400: "Jordan",
    ISO_3166_398: "Kazakhstan",
    ISO_3166_404: "Kenya",
    ISO_3166_296: "Kiribas",
    ISO_3166_408: "North Korea",
    ISO_3166_410: "Korea",
    ISO_3166_116: "Cambodia",
    ISO_3166_174: "Comoros",
    ISO_3166_414: "Kuwait",
    ISO_3166_417: "Kyrgyzstan",
    ISO_3166_136: "Cayman Islands",
    ISO_3166_418: "In stock",
    ISO_3166_144: "Sri Lanka",
    ISO_3166_428: "Latvia",
    ISO_3166_422: "Lebanon",
    ISO_3166_426: "Lesotho",
    ISO_3166_430: "Liberia",
    ISO_3166_434: "Libya",
    ISO_3166_438: "Liechtenstein",
    ISO_3166_440: "Lithuania",
    ISO_3166_442: "Luxembourg",
    ISO_3166_450: "Madagascar",
    ISO_3166_454: "Malawi",
    ISO_3166_458: "Malaysia",
    ISO_3166_462: "Maldives",
    ISO_3166_466: "Mali",
    ISO_3166_470: "Malta",
    ISO_3166_584: "Marshall Islands",
    ISO_3166_478: "Mauritania",
    ISO_3166_480: "Mauritius",
    ISO_3166_484: "Mexico",
    ISO_3166_498: "Moldova",
    ISO_3166_492: "Monaco",
    ISO_3166_496: "Mongolia",
    ISO_3166_504: "Morocco",
    ISO_3166_508: "Mozambique",
    ISO_3166_104: "Myanmar",
    ISO_3166_516: "Namibia",
    ISO_3166_520: "Nauru",
    ISO_3166_524: "Nepal",
    ISO_3166_528: "Netherlands",
    ISO_3166_540: "New Caledonia",
    ISO_3166_554: "New Zealand",
    ISO_3166_558: "Nicaragua",
    ISO_3166_562: "Niger",
    ISO_3166_566: "Nigeria",
    ISO_3166_570: "Niue Island",
    ISO_3166_578: "Norway",
    ISO_3166_512: "Oman",
    ISO_3166_586: "Pakistan",
    ISO_3166_260: "French Polynesia",
    ISO_3166_585: "Palau",
    ISO_3166_591: "Panama",
    ISO_3166_598: "Papua New Guinea",
    ISO_3166_600: "Paraguay",
    ISO_3166_604: "Peru",
    ISO_3166_608: "Philippines",
    ISO_3166_612: "Pitcairn Islands",
    ISO_3166_616: "Poland",
    ISO_3166_620: "Portugal",
    ISO_3166_630: "Puerto Rico",
    ISO_3166_634: "Qatar",
    ISO_3166_642: "Romania",
    ISO_3166_643: "Russia",
    ISO_3166_646: "Rwanda",
    ISO_3166_222: "El Salvador",
    ISO_3166_654: "St. Helena",
    ISO_3166_674: "San Marino",
    ISO_3166_678: "Sao Tome and Principe",
    ISO_3166_682: "Saudi Arabia",
    ISO_3166_686: "Senegal",
    ISO_3166_690: "Seychelles",
    ISO_3166_694: "Sierra Leone",
    ISO_3166_702: "Singapore",
    ISO_3166_703: "Slovakia",
    ISO_3166_705: "Slovenia",
    ISO_3166_090: "Solomon Islands",
    ISO_3166_706: "Somalia",
    ISO_3166_736: "Sudan",
    ISO_3166_740: "Suriname",
    ISO_3166_748: "Swaziland",
    ISO_3166_752: "Sweden",
    ISO_3166_760: "Syria",
    ISO_3166_810: "Soviet Union",
    ISO_3166_148: "Chad",
    ISO_3166_762: "Tajikistan",
    ISO_3166_834: "Tanzania",
    ISO_3166_764: "Thailand",
    ISO_3166_768: "Togo",
    ISO_3166_772: "Tokla",
    ISO_3166_776: "Tonga",
    ISO_3166_780: "Trinidad and Tobago",
    ISO_3166_788: "Tunisia",
    ISO_3166_792: "Turkey",
    ISO_3166_626: "East Timor",
    ISO_3166_795: "Turkmenistan",
    ISO_3166_798: "Tuvalu",
    ISO_3166_800: "Uganda",
    ISO_3166_804: "Ukraine",
    ISO_3166_840: "United States",
    ISO_3166_858: "Uruguay",
    ISO_3166_860: "Uzbekistan",
    ISO_3166_336: "Vatican",
    ISO_3166_862: "Venezuela",
    ISO_3166_882: "Western Samoa",
    ISO_3166_887: "Yemen",
    ISO_3166_891: "Yugoslavia",
    ISO_3166_710: "South Africa",
    ISO_3166_180: "Zaire",
    ISO_3166_894: "Zambia",
    ISO_3166_716: "Zimbabwe",
    other: "Other",
    ISO_3166_074: "Boway Island",
  },
  "已有账户？": "Already have an account？",
  立即登录: "Login now.",
  立即注册: "Register Now",
  发送验证码成功:
    "Verification code sent successfully！In case a verification code doesn't arrive, check your Spam/Junk folder. ",
  search: "Search",
};
