import React from 'react';
// import {createRoot} from 'react-dom/client';
import ReactDOM from "react-dom";
import './globals.scss';
import Ruoter from './ruoter';
import "./lang/i18n.js";


// createRoot(document.getElementById('root')).render(<Ruoter />);


ReactDOM.render(
    <React.StrictMode>
      <Ruoter />
    </React.StrictMode>,
    document.getElementById("root")
  );
