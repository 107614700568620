export  const tc = {
    login: {
        "App Store 下载":"App Store",
		"Google Play 下载":"Google Play",
        "注册":"立即註冊",
        "title": "用Visa/Master信用卡<br/>購買虛擬貨幣！",
        title2: "14000USDT",
        title3: "新人專享好禮",
        "li1": "BTCC成立於 2011 年，是全球營運時間最長的虛擬貨幣交易所",
        "li2": "美國 MSB 及 加拿大 MSB 牌照",
        "li3": "提供18種以上虛擬貨幣交易",
        "li4": "支持 9 種以上法定貨幣存款",
        "li5": "提供 1 - 150 的槓桿進行交易",
        "li6": "註冊成功後7天內完成充值目標，即可享相應等級贈金",
        "首页url":"https://lp.btcc.academy/zh-TW",
        "首页":"首頁",
        "关于我们":"關於BTCC",
        "about":"zh-TW/about-tw.html",
        "about_in":"about_in-tw.html",
        "about_dm":"about_dm-tw.html",
        "about_coupon":"about_coupon-tw.html",
        "about_rebate":"about_rebate-tw.html",
        "about_lottery":"about_lottery-tw.html",
        "tab1":"郵件注冊",
        "email": "請輸入電子郵件地址",
        "code": "請輸入驗證碼",
        "mobicode": "請輸入手機驗證碼",
        "Send": "取得驗證碼",
        "pwd": " 請設定密碼",
        "tab2": "手機注冊",
        "mobile": "請輸入手機號碼",
        "Register": "立即註冊",
        "服务条款":"https://www.btcc.com/zh-TW/detail/142643.html",
        "隐私声明":"https://www.btcc.com/zh-TW/detail/142639.html",
        "风险提示":"https://www.btcc.com/zh-TW/detail/149035.html",



        "mobileTip": "請輸入正確的手機號碼",
        "emailTip": "請輸入正確的郵箱地址",
        "codeTip": "請輸入正確的驗證碼",
        "pwdTip": "至少8位字符，必須包含大小寫字母、數字或特殊符號",
        "tips":" 我已閱讀，並接受",
		"tips1":"《服務條款》",
        "tips2":"《隱私協議》",
		"tips3":"《風險提示》" ,
		"tipslang":"https://www.btcc.com/detail/142641.html",

        registeSuccessInfo: "註冊成功，開始您的加密貨幣之旅！",
        startTrading: "立即下載BTCC領取新人獎勵！",
        registeSuccessInfoPhone: "註冊成功，開始您的加密貨幣之旅！",
        startTradingPhone: "立即下載BTCC領取新人獎勵！",

       
    },
    header:{
        充值:"充值",
        交易:"交易",
        学习:"學習",
        最新活动:"活動中心",
        更多:"更多",
        充幣:"充幣",
        買幣:"買幣",
        合約:"合約",
        閃兌:"閃兌",
        學院:"學院",
        資訊:"資訊",
        幫助中心:"幫助中心",
        官方部落格:"官方部落格",
        新用戶專享:"新用戶專享",
        交易回饋:"交易回饋",
        邀请好友:"邀请好友",
        公告中心:"公告中心",
        下載中心:"下載中心",
        推薦計劃:"推薦計劃",
        VIP升級:"VIP升級",
        註冊:"註冊",
       
      },
    SupportedCurrencies: {
        title: "支持的貨幣",
        title2:"為什麼選擇BTCC交易加密貨幣？",
        li1_1: "BTCC設立於 2011 年，是全球營運時間最長、安全係數最高的交易所",
        li2: "持有美國 MSB 及 加拿大 MSB 牌照",
        li3: "支援18種以上主流加密貨幣",
        li4: "提供 10- 150 倍槓桿",
        li5: "14000USDT新人專享首存贈金活動",
        li6: "專屬LINE社群，提供加密貨幣交易資訊",
        p: "選擇您使用的貨幣來為您的賬戶存款和注資。",
    },
    LeverageUp: {
        title: "18種主流虛擬貨幣最高提供150倍槓桿",
        head1: "槓桿",
        head2: "虛擬貨幣",
        p: "未來將引入更多虛擬貨幣"
    },
    cost: {
      title: "比全球收費標準更低",
      p: "BTCC的收費其他虛擬貨幣交易所更低更吸引，將您的每筆交易成本降到最低。",
      h3: "手續費",
      p2: "我們為您的每一筆交易提供低至0.03%的交易費。在您的交易過程中，成本將不是負擔！",
      h4: "點差",
      p3: "對下單猶豫不決？我們提供最準確的報價，讓您獲得最小的買賣差價。",
      p4: "其他交易平台"

    },
    btns: {
        text1: "立即注冊",
        text2: "下載BTCC APP"
    },
    step: {
        title: "在 30 秒內開始交易",
        step1: "下載",
        step2: "註冊",
        step3: "入金",
        step4: "交易",
        Download:"下載BTCC APP"

    },
    BTCC: {
        title: "客戶如何評價 BTCC?",
        p: "深受新手和專業投資者的信賴",
        h4: "首選期貨平台",
        p2: "一直都在試不同的期貨交易平台，最後找到了BTCC。他們無點差、手續費低、還有完善安全的系統和吸引的活動。",
        head2: "susan",
        head3: "10年營運都沒有被黑客入侵",
        p3: "經常聽到新聞說虛擬貨幣交易所被黑客入侵。BTCC開了10年都沒有安全問題，令我可以放心使用。",
        head4: "Nom1246",
        head5: "APP設計清晰對新手友善",
        p4: "之前沒有玩過期貨, 下載了他們的APP看了看發現有模擬交易功能. 還有一堆新手教學和官方LINE客服, 有甚麼不懂都能問呢!",
        head6: "benk90y",

        
        h42:"👍👍",
		p22:"用一段時間發現蠻好的！ 👍👍而且App定期更新，推出新功能不會悶！",
		head22:"Garryy",
		head32:"價格第一",
		p32: "UI好看方便用，價格也比其他平台好～期貨交易都在這家做了～",
        head42: "coincoin",
        head52: "出入金流暢",
        p42: "我選交易平台的時候首先會看存款取款是否正常，這家挺快的！",
        head62: "慳致富",

		h43:"交易手續費低！！",
		p23:"交易手續費是我知道的最便宜的！現在交易時都不會太在意手續費",
		head23:"miket",
		head33:"活動多又吸引",
		p33: "他們差不多每個月都會有活動，大部分都是參與就可以得獎金超讚的～獎金還可以直接出金，覺得有賺到了呢！",
        head43: "ship4082",
        head53: "用用看",
        p43: "發現很不錯。交易流暢方便，感覺成交量挺大的。也有很多實用好玩的功能實時報價希望可以再加不同幣～～",
        head63: " 曾文文",
        btcc:"btcc",
    },
    Industry: {
        title: "熱門行業新聞",
        industrynone:"industry-none",
        行業重點資訊: "行業重點資訊",
        大公司:"10 家接受比特幣的大公司",
        雅虎財經:"雅虎財經",
        隨著科技巨頭:"隨著科技巨頭、流媒體平台和食品連鎖店採用加密貨幣作為支付方式，比特幣正在朝著成為一種國際貨幣邁進。",
        比特幣比電匯便宜:"比特幣比電匯便宜 300 倍，銀行獲利 83%",
        BTC新聞:"BTC新聞",
        比特幣透過擴大:"比特幣透過擴大區塊鏈的交易容量來克服可擴展性問題，導致交易費用比電匯便宜得多。",
        薩爾瓦多:"薩爾瓦多成為第一個採用比特幣作為本國貨幣的國家",
        華爾街日報:"華爾街日報",
        在經歷了比特幣:"在經歷了比特幣錢包斷開連結的技術挫折之後，薩爾瓦多政府繼續吹捧採用比特幣作為吸引貧困國家投資的法定貨幣。",
        美國首個比特幣期貨:"美國首個比特幣期貨 ETF 將於下週開始交易",
        美國全國廣播公司財經頻道:"美國全國廣播公司財經頻道",
        Proshares:"Proshares 的第一隻比特幣期貨 ETF 將於本週開始交易，標誌著美國加密行業發展的里程碑時刻。"
    },
    Foot:{
		text:"風險提示：數字資產交易是一個新興產業，存在光明前景，但正因為屬於新事物，也蘊含巨大風險。特別是在槓桿交易中，槓桿倍數既同步擴大了盈利也同步放大了風險，請您務必對此行業、槓桿交易模式、交易規則等相關知識有充分了解後再參與。並且我們強烈建議您，在自身能夠承擔的風險範圍內進行交易。交易有風險，入市須謹慎！",
		text2:"全球運營最久的交易所，穩健運營10週年 © 2011-2022 BTCC.com. All rights reserved",
        服務:"服務",
        永續合約:"永續合約",
        當週合約:"當週合約",
        當日合約:"當日合約",
        支持:"支持",
        最新公告:"最新公告",
        帮助中心:"帮助中心",
        費率標準:"費率標準",
        即時數據:"即時數據",
        條款:"條款",
        服務條款:"服務條款",
        隱私聲明:"隱私聲明",
        風險提示:"風險提示",
        客戶服務:"客戶服務",
        在线客服:"在线客服",
        社區:"社區",
        全球社區:"全球社區",
        快速鏈接:"快速鏈接",
        如何在BTCC買賣比特幣合約:"如何在BTCC買賣比特幣合約",
        如何在BTCC買賣以太坊期貨:"如何在BTCC買賣以太坊期貨",
        如何在BTCC買賣萊特幣期貨:"如何在BTCC買賣萊特幣期貨",
        如何在BTCC買賣BCH比特幣現金:"如何在BTCC買賣BCH比特幣現金",
        如何在BTCC買賣EOS柚子幣期貨:"如何在BTCC買賣EOS柚子幣期貨",
        如何在BTCC買賣瑞波幣XRP永續合約:"如何在BTCC買賣瑞波幣XRP永續合約",
        如何在BTCC買賣艾達幣ADA永續合約:"如何在BTCC買賣艾達幣ADA永續合約",
        如何在BTCC買賣恆星幣XLM永續合約:"如何在BTCC買賣恆星幣XLM永續合約",
        如何在BTCC買賣達世幣永續合約:"如何在BTCC買賣達世幣永續合約",
        客服:"https://btccoverseas.s5.udesk.cn/im_client/?web_plugin_id=181&group_id=106&language=zh-HK&platform=GW",
        telegram:"https://t.me/BTCCofficial01",
        weibo:"https://weibo.com/p/1006062149945883/home",
        bilibili:"https://space.bilibili.com/484111839",
        twitter:"https://twitter.com/YourBTCC",
        facebook:"https://www.facebook.com/yourbtcc/",
        linkedin:"https://www.linkedin.com/company/yourbtcc/",
        instagram:"https://www.instagram.com/btccbitcoin",
        btccblog:"https://medium.com/btccblog",
        reddit:"https://www.reddit.com/r/YourBTCC/",
        比特幣合約:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-trader-btc-in-btcc",
        以太坊期貨:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-buy-and-sell-ether-eth-at-btcc",
        萊特幣期貨:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-trader-litecoin-ltc-on-btcc",
        比特幣現金:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-buy-and-sell-bitcoin-cash-bch",
        柚子幣期貨:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-trader-grapefruit-eos-in-btcc",
        XRP永續合約:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-trader-xrp-in-btcc",
        ADA永續合約:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-trader-ada-in-btcc",
        XLM永續合約:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-traverse-stars-xlms-in-btcc",
        達世幣永續合約:"https://www.btcc.com/zh-TW/academy/crypto-basics/how-to-buy-and-sell-in-btcc-dash",
	},
    bonus:{
        注册免费获取:"註冊免費獲取",
        模拟账户:"模擬帳戶",
        轻松训练提升交易技能:"輕鬆訓練提升交易技能",
        注册获取模拟账户:"註冊獲取模擬帳戶",
        如何获取模拟账户:"如何獲取模擬帳戶",
        第一步注册账户:"第一步：註冊帳戶",
        第二步下载APP:"第二步：下載APP",
        第三步APP登录:"第三步：APP登錄",
        第四步切换模拟账户:"第四步：切換模擬帳戶",
        第二步进入交易界面:"第二步：進入交易介面",
        第三步账号登录:"第三步：帳號登錄",
        新用户专享豪礼:"新用戶專享豪禮",
        "14000USDT":"14000USDT",
        注册后7天可领取:" 註冊後7天可領取",
        立即注册领取:"立即註冊領取",
        如何领取新用户豪礼:"如何領取新用戶豪禮",
        第二步下载官方APP:"第二步：下載官方APP",
        第三步用注册的账户登录:"第三步：用註冊的帳戶登錄",
        第四步APP内报名领取奖励:"第四步：APP內報名領取獎勵",
        第二步进入官网:"第二步：進入官網",
        第三步用注册的账户登录:"第三步：用注册的账户登录",
        第四步官网内报名领取奖励:"第四步：官網內報名領取獎勵",
    },
    bonus2:{
        "tab1":"信箱註冊",
        "email": "請輸入電子信箱",
        title: "支援18種主流加密貨幣 槓桿率可高達150倍",
        head1: "槓桿率",
        head2: "加密貨幣",
        p: "未來將引入更多加密貨幣",
        p1: "BTCC的收費遠比其他加密貨幣交易所更低，極具競爭力的價差讓您可以享受到低價交易的優勢。",
        p2: "每筆交易手續費低至0.03%，給您更好的交易體驗。",
        p3: "下單時猶豫不決？我們提供最準確的報價，讓您可以以最小價差進行交易。",
        googleplay:"/FT_google.svg",
        appstore:"/FT_Apple.svg",
    },
    strategy:{
        交易攻略:"交易攻略",
        突發:"突發！比特幣跳水，何時抄底？",
        加密怪獸:"加密怪獸",
        目前來講:"目前來講4小時級別剛剛開始就暴跌，現在看似跌破，但是不建議馬上去追空。想要去追空，一定要等這一根K線完全收線之後，完成大陰線跌破我們再去考慮。",
        如何投資:"如何投資比特幣及其他加密貨幣",
        BTCC學院:"BTCC學院",
        比特幣:"比特幣可能是最受歡迎的代幣之一，但不是唯一。當購買加密貨幣時，其他加密貨幣亦會是一個增加組合多樣性的不錯選擇。",
        基本分析:"基本分析 vs. 技術分析",
        評估:"基本分析涉及評估整個項目資產的潛力，包括其應用、團隊、白皮書、開發、行銷、管理、聲譽、長期目標及其他因素等。相比之下，技術分析（TA）會考慮以往的價格走勢和交易量數據，以嘗試預測未來的價格走勢，該技術通常涉及陰陽燭圖表和TA指標，例如移動平均線及趨勢線。",
        什麼是加密貨幣:"什麼是加密貨幣交易中的槓桿？",
        在加密貨幣交易中:"在加密貨幣交易中，槓桿是指使用借入資金進行交易。槓桿交易可放大您的買入或出售能力，讓您進行更大的交易。因此，即使您的初始資本很小，您也可以將其用作抵押品進行槓桿交易。",
    },
    lottery:{
        幸运转大奖:"幸運轉盤",
        每日送1BTCETH:"每天送1BTC & ETH",
        "10000USDTXRP":"10,000USDT & XRP",
        活动规则:"活動注意事項：",
        注册账户后并下载手机APP参与活动期间每天从:"注冊賬戶后並下載手機APP參與，活動期間，所有用戶每天",
        "8点":"8時",
        开始宝箱倒计时结束即可抽奖:"起，寶箱倒數計時每結束1次，便可抽1次獎，每天上限",
        抽奖仅3次:"抽獎3次",
        次日8点之前未抽奖的机会将失效 :"，未抽獎的機會，將在次日8時失效，最大獎金",
        "比特币(BTC)":"1個BTC！",
        "10000USDT模拟账户":"10,000USDT模擬帳戶",
        BTCC为您准备了高达:"BTCC為您準備了高達",
        "10000USDT":"10,000USDT",
        的模拟资金供您体验模拟交易:"的模擬資金供您體驗模擬交易，這可以幫助您快速了解市場行情及交易規則，以便在真實交易中更快實現盈利。",
        "title": "用Visa/Master信用卡購買虛擬貨幣！",
        "li1": "BTCC成立於 2011 年，是全球營運時間最長的虛擬貨幣交易所",
    },
    coupon:{
        充就送10USDT:"充值送10USDT",
        "0.003BTC以上":" 充幣≥0.003BTC（或充幣≥0.04ETH,或≥100XRP） 法幣購買≥100USDT（或充幣≥100USDT)",
        注册账户后在活动期间充值:"注冊賬戶后，在活動期間，完成以下任一條件，可領取",
        "100USDT以上":"10USDT",
        即可获得10USDT赠金:"即可获得10USDT赠金。",
        活动规则:"活動注意事項：",
        "每个账户":"*每個賬戶",
        仅限领取1次:"僅限領取1次",
        领取后立即到账BTCC账户:"，領取後立即到賬BTCC賬戶，贈金可以抵扣用戶在BTCC交易時產生的手續費用、過夜利息及交易虧損的金額補貼。",
    },
    rebate:{
        "交易返现":"交易返現",
        "500USDT":"500USDT",
        注册账户后:"注冊交易賬戶后, 在活動期間進行合約交易, 所產生的手續費，將在交易次日24小時內返現15%, 最多返利",
        "500USDT":"500USDT", 
    },
    countryList: {
        code:"886",
        ISO_3166_704: '越南',
        ISO_3166_156: '中國',
        ISO_3166_344: '中國香港',
        ISO_3166_158: '台灣',
        ISO_3166_446: '中國澳門',
        ISO_3166_004: '阿富汗',
        ISO_3166_008: '阿爾巴尼亞',
        ISO_3166_020: '安道爾',
        ISO_3166_024: '安哥拉',
        ISO_3166_010: '南極洲',
        ISO_3166_028: '安提瓜和巴布達',
        ISO_3166_032: '阿根廷',
        ISO_3166_051: '亞美尼亞',
        ISO_3166_533: '阿魯巴',
        ISO_3166_036: '澳大利亞',
        ISO_3166_040: '奧地利',
        ISO_3166_031: '阿塞拜疆',
        ISO_3166_784: '阿拉伯聯合酋長',
        ISO_3166_044: '巴哈馬',
        ISO_3166_048: '巴林',
        ISO_3166_050: '孟加拉國',
        ISO_3166_052: '巴巴多斯',
        ISO_3166_112: '巴巴多斯',
        ISO_3166_084: '伯利茲',
        ISO_3166_056: '比利時',
        ISO_3166_204: '貝寧',
        ISO_3166_060: '百慕大群島',
        ISO_3166_064: '不丹',
        ISO_3166_068: '玻利維亞',
        ISO_3166_070: '波斯尼亞黑塞哥維那',
        ISO_3166_072: '博茨瓦納',
        ISO_3166_076: '巴西',
        ISO_3166_096: '文萊',
        ISO_3166_100: '保加利亞',
        ISO_3166_854: '布基納法索',
        ISO_3166_108: '布隆迪',
        ISO_3166_120: '喀麥隆',
        ISO_3166_124: '加拿大',
        ISO_3166_140: '中非共和國',
        ISO_3166_152: '智利',
        ISO_3166_162: '聖誕節島',
        ISO_3166_166: '可可群島',
        ISO_3166_170: '哥倫比亞',
        ISO_3166_756: '瑞士',
        ISO_3166_178: '剛果',
        ISO_3166_184: '庫克島',
        ISO_3166_188: '哥斯達黎加',
        ISO_3166_384: '象牙海岸',
        ISO_3166_192: '古巴',
        ISO_3166_196: '塞浦路斯',
        ISO_3166_203: '捷克共和國',
        ISO_3166_208: '丹麥',
        ISO_3166_262: '吉布提',
        ISO_3166_276: '德國',
        ISO_3166_214: '多米尼加共和國',
        ISO_3166_012: '阿爾及利亞',
        ISO_3166_218: '厄瓜多爾',
        ISO_3166_818: '埃及',
        ISO_3166_732: '西撒哈拉',
        ISO_3166_724: '西班牙',
        ISO_3166_233: '愛沙尼亞',
    
        ISO_3166_231: '埃塞俄比亞',
        ISO_3166_242: '斐濟',
        ISO_3166_238: '福克蘭群島',
        ISO_3166_246: '芬蘭',
        ISO_3166_250: '法國',
        ISO_3166_583: '密克羅尼西亞',
        ISO_3166_266: '加蓬',
        ISO_3166_226: '赤道幾內亞',
        ISO_3166_254: '法屬圭亞那',
        ISO_3166_270: '岡比亞',
        ISO_3166_239: '喬治亞州',
        ISO_3166_288: '加納',
        ISO_3166_292: '直布羅陀',
        ISO_3166_300: '希臘',
        ISO_3166_304: '格陵蘭',
        ISO_3166_826: '英國',
        ISO_3166_308: '格林納達',
        ISO_3166_312: '瓜德羅普島',
        ISO_3166_316: '關島',
        ISO_3166_320: '危地馬拉',
        ISO_3166_324: '幾內亞',
        ISO_3166_624: '幾內亞比紹',
        ISO_3166_328: '圭亞那',
        ISO_3166_191: '克羅地亞',
        ISO_3166_332: '海地',
        ISO_3166_340: '洪都拉斯',
        ISO_3166_348: '匈牙利',
        ISO_3166_352: '冰島',
        ISO_3166_356: '印度',
        ISO_3166_360: '印度尼西亞',
        ISO_3166_364: '伊朗',
        ISO_3166_368: '伊拉克',
        ISO_3166_086: '英國印度洋領地',
        ISO_3166_372: '愛爾蘭',
        ISO_3166_376: '以色列',
        ISO_3166_380: '意大利',
        ISO_3166_388: '牙買加',
        ISO_3166_392: '日本',
        ISO_3166_400: '約旦',
        ISO_3166_398: '哈薩克斯坦',
        ISO_3166_404: '肯尼亞',
        ISO_3166_296: '基里巴斯',
        ISO_3166_408: '朝鮮',
        ISO_3166_410: '韓國',
        ISO_3166_116: '柬埔寨',
        ISO_3166_174: '科摩羅',
        ISO_3166_414: '科威特',
        ISO_3166_417: '吉爾吉斯斯坦',
        ISO_3166_136: '開曼群島',
        ISO_3166_418: '有庫存',
        ISO_3166_144: '斯里蘭卡',
        ISO_3166_428: '拉脫維亞',
        ISO_3166_422: '黎巴嫩',
        ISO_3166_426: '萊索托',
        ISO_3166_430: '利比里亞',
        ISO_3166_434: '利比亞',
        ISO_3166_438: '列支敦士登',
        ISO_3166_440: '立陶宛',
        ISO_3166_442: '盧森堡',
        ISO_3166_450: '馬達加斯加',
        ISO_3166_454: '馬拉維',
        ISO_3166_458: '馬來西亞',
        ISO_3166_462: '馬爾代夫',
        ISO_3166_466: '馬里',
        ISO_3166_470: '馬耳他',
        ISO_3166_584: '馬紹爾群島',
        ISO_3166_478: '毛里塔尼亞',
        ISO_3166_480: '毛里求斯',
        ISO_3166_484: '墨西哥',
        ISO_3166_498: '摩爾多瓦',
        ISO_3166_492: '摩納哥',
        ISO_3166_496: '蒙古',
        ISO_3166_504: '摩洛哥',
        ISO_3166_508: '莫桑比克',
        ISO_3166_104: '緬甸',
        ISO_3166_516: '納米比亞',
        ISO_3166_520: '瑙魯',
        ISO_3166_524: '尼泊爾',
        ISO_3166_528: '荷蘭',
        ISO_3166_540: '喀裡多尼亞',
        ISO_3166_554: '新西蘭',
        ISO_3166_558: '尼加拉瓜',
        ISO_3166_562: '尼日爾',
        ISO_3166_566: '尼日利亞',
        ISO_3166_570: '紐埃島',
        ISO_3166_578: '挪威',
        ISO_3166_512: '阿曼',
        ISO_3166_586: '巴基斯坦',
        ISO_3166_260: '法屬波利尼西亞',
        ISO_3166_585: '帕勞',
        ISO_3166_591: '巴拿馬',
        ISO_3166_598: '巴布亞新幾內亞',
        ISO_3166_600: '巴拉圭',
        ISO_3166_604: '秘鲁',
        ISO_3166_608: '菲律賓',
        ISO_3166_612: '皮特凱恩群島',
        ISO_3166_616: '波蘭',
        ISO_3166_620: '葡萄牙',
        ISO_3166_630: '波多黎各',
        ISO_3166_634: '卡塔爾',
        ISO_3166_642: '羅馬尼亞',
        ISO_3166_643: '俄罗斯',
        ISO_3166_646: '盧旺達',
        ISO_3166_222: '薩爾瓦多',
        ISO_3166_654: '聖赫勒拿島',
        ISO_3166_674: '聖馬力諾',
        ISO_3166_678: '聖多美和普林西比',
        ISO_3166_682: '沙特阿拉伯',
        ISO_3166_686: '塞內加爾',
        ISO_3166_690: '塞舌爾',
        ISO_3166_694: '塞拉利昂',
        ISO_3166_702: '新加坡',
        ISO_3166_703: '斯洛伐克',
        ISO_3166_705: '斯洛文尼亞',
        ISO_3166_090: '所羅門群島',
        ISO_3166_706: '索馬里',
        ISO_3166_736: '蘇丹',
        ISO_3166_740: '蘇里南',
        ISO_3166_748: '斯威士蘭',
        ISO_3166_752: '瑞典',
        ISO_3166_760: '敘利亞',
        ISO_3166_810: '蘇聯',
        ISO_3166_148: '乍得',
        ISO_3166_762: '塔吉克斯坦',
        ISO_3166_834: '坦桑尼亞',
        ISO_3166_764: '泰國',
        ISO_3166_768: '多哥',
        ISO_3166_772: '托克拉',
        ISO_3166_776: '湯加',
        ISO_3166_780: '特立尼達和多巴哥',
        ISO_3166_788: '突尼斯',
        ISO_3166_792: '土耳其',
        ISO_3166_626: '東帝汶',
        ISO_3166_795: '土庫曼斯坦',
        ISO_3166_798: '圖瓦盧',
        ISO_3166_800: '烏干達',
        ISO_3166_804: '烏克蘭',
        ISO_3166_840: '美國',
        ISO_3166_858: '烏拉圭',
        ISO_3166_860: '烏茲別克斯坦',
        ISO_3166_336: '梵蒂岡',
        ISO_3166_862: '委內瑞拉',
        ISO_3166_882: '薩摩亞西部',
        ISO_3166_887: '也門',
        ISO_3166_891: '南斯拉夫',
        ISO_3166_710: '南非',
        ISO_3166_180: '扎伊爾',
        ISO_3166_894: '贊比亞',
        ISO_3166_716: '津巴布韋',
        other: '其他',
        ISO_3166_074: '布威島',
      },
      "已有账户？":"已有帳戶？",
      "立即登录":"立即登入",
      "立即注册":"立即註冊",
     "发送验证码成功": "發送驗證碼成功！如果沒有收到驗證碼，請檢查您的垃圾郵件資料夾。",
     search: "搜索",

    
}